import React, {useState, useMemo} from 'react';
import {songs} from '../../data/musicData';
import SongBanner from '../../features/songsBanner/SongBanner';
import './visualsPage.css';
import BigVideo from '../../features/bigVideo/bigVideo';
import {shuffleArray} from '../../features/functions';

const ForwardButton = ({ onClick }) => (
    <button onClick={onClick} className='loadButContent'>
        <div class="text">
            <span>► NEXT ►</span>
        </div>
        <div class="clone">
            <span>► NEXT ►</span>
        </div>
    </button>
);

const BackwardButton = ({ onClick }) => (
    <button onClick={onClick} className='loadButContent'>
        <div class="text">
            <span>◄ PREV ◄ </span>
        </div>
        <div class="clone">
            <span>◄ PREV ◄</span>
        </div>
    </button>
);

export default function VisualsPage(){
    document.title = 'VISUALS | ACMM OFFICIAL';
    const [ranSongs, setRanSongs] = useState([]);
    const [id, setId] = useState("2fThupjTEX0")
    const ids = ranSongs.map((el) => el.videoId); 
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex < ids.length - 1 ? prevIndex + 1 : prevIndex;
            setId(ids[newIndex]); 
            return newIndex; 
        });
    };
    
    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
            setId(ids[newIndex]); 
            return newIndex; 
        });
    };

    useMemo(()=>{
        setRanSongs(shuffleArray(songs));
    },[])


    
    return (
        <section>
            <div id='project'>
                    <div className='textSectionAbout'>
                    <div className='textAbout'>
                        <h1>- A. C. M. M:</h1>
                        <p>
                        "Like a play, where passions bloom,<br/>
                        And conquer hearts within the room,<br/>
                        A tale unfolds, a mask to wear,<br/>
                        Hiding drama, yet we stare."
                        <br/><br/>
                        "An eternal script, in ribbons bound,<br/>
                        A bouquet left, where ends are found.<br/>
                        Clichés mock longing’s fragile glow,<br/>
                        Yet still, we marvel at the show."
                        <br/><br/>
                        "When curtains fall, the shadows rise,<br/>
                        We linger, lost in quiet skies.<br/>
                        Will this story cast its spell,<br/>
                        Or fade, like many others fell?"
                        <br/><br/>
                        "Where longing dreams and truth collide,<br/>
                        Reality stands, equally tried."
                        </p>
                    </div>
                    <div id='imgAbout'>
                        <div className='centerAll'>
                            <div id='latestVideo'>
                                <BigVideo vidId={`${id}`}/>
                            </div>
                        </div>
                    </div>
                    </div>
                    {/* ARROWS */}
                    
                    <div id='arrows'>
                            <BackwardButton onClick={handlePrevious} />
                            <ForwardButton onClick={handleNext} />
                    </div>

                    <div className='testMusicPage'></div>

                    <div>
                        <SongBanner/>
                    </div>
                </div>
        </section>
    );
}