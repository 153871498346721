import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import './phraseActions.css';

export default function PhraseActions() {
  const phrases = [
    (
      <>
        ➤ {' '}
        <Link to="/visuals" style={{ textDecoration: 'underline' }}>
        NEW MUSIC VIDEOS
        </Link>{' '}
      </>
    ),
    (
      <>
        ➤ FOLLOW A. C. M. M - {' '}

        <a
          style={{ textDecoration: 'underline' }}
          href="https://www.instagram.com/A.C.M.M_/"
          target="_blank"
          rel="noreferrer"
        >
          INSTAGRAM
        </a>
      </>
    ),
    (
      <>
        ➤
        <a
          style={{ textDecoration: 'underline' }}
          href="https://linktr.ee/acmmofficial"
          target="_blank"
          rel="noreferrer"
        >
        HEAR MUSIC NOW
        </a>

      </>
    ),
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % phrases.length);
    }, 4000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [phrases.length]);

  return (
    <section id="textActions">
      <div id="text-carousel" style={{ padding: '1%' }}>
        {phrases.map((phrase, index) => (
          <h3 key={index} style={{ display: index === currentIndex ? 'block' : 'none' }}>
            {phrase}
          </h3>
        ))}
      </div>
    </section>
  );
}