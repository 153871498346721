import {summaries}  from './summaries';
import {formatString} from './musicData';
export const albums = [
    {
        id: 1,
        title: "Parallel",
        actNumber: "Act 0",
        date:'2024-06-20',
        year: '2024',
        type: 'album',
        artist: 'ACMM',
        path: 'parallel',
        imageUrl: 'Parallel.png',
        embed: '2Z8RkLLfdO1BTP5GKbzkFa?si=37473072e434431c&nd=1&dlsi=f26b7f2ce3fb4e71',
        info: {genre: 'Alternative',
               author: 'Arturo Cesar Morales Montano',
               duration: '12 min',
               numsongs: '3',
               textSummary: summaries.IJASL.summary,
               authorText: summaries.IJASL.author,
               story: formatString(summaries.IJASL.story),
               secondName: "Parallel | It's Just Another Story Of Love"
              },
      },
  ];