import {React, useState, useEffect} from 'react';
import {Outlet} from 'react-router-dom';
/* COMPONENTS */
import Header from '../components/Header/Header';
import PhoneHeader from '../components/PhoneHeader/PhoneHeader';
import SocialMedia from '../components/SocialMedia/SocialMedia';
import PhraseActions from '../components/PhraseActions/PhraseActions';
import Subscription from '../components/Subscription/Subscription';
import Footer from '../components/Footer/Footer';

export default function AppLayout() {
    const [isNavPhoneVisible, setNavPhoneVisibility] = useState(false);
    const [isSocialMediaVisible, setSocialMediaVisibility] = useState(true);

    /* NAVIGATION */
    const toggleNavPhone = () => {
      setNavPhoneVisibility(!isNavPhoneVisible);
    };
  
    const toggleSocialMedia = () => {
      setSocialMediaVisibility(!isSocialMediaVisible);
    };
  
    useEffect(() => {
        /* SOCIAL MEDIA */
      const handleResize = () => {
        if (window.innerWidth < 700) {
          setSocialMediaVisibility(false);
        } else {
          setSocialMediaVisibility(true);
        }
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    useEffect(() => {
      /* Phone Navigation */
      const handlePhone = () => {
        if (window.innerWidth < 950 && window.innerWidth > 700) {
            setNavPhoneVisibility(true);
        } else if (window.innerWidth < 700){
            setNavPhoneVisibility(false);
        } else{
            setNavPhoneVisibility(false);
        }
      };
      handlePhone();
      window.addEventListener('resize', handlePhone);
      return () => {
        window.removeEventListener('resize', handlePhone);
      };
    }, []);

    return (
    <>
    {/* GO TO */}
    <div id="top"></div>
    {/* HEADER */}
    <Header toggleNavPhone={toggleNavPhone} toggleSocialMedia={toggleSocialMedia}/>
    <div id="content-container">
        {/* Phone Header */}
        <PhoneHeader isNavPhoneVisible={isNavPhoneVisible}/>
        {/* CONTENT */}
        <div id="main_page">
            {/* Social Media */}
            <SocialMedia isSocialMediaVisible={isSocialMediaVisible}/>
            {/* MAIN CONTENT */}
            <div id="core-wrapper" className="col-12 col-lg-11 col-xl-9 pe-xl-4">
                <div>
                    {/* GO TOP  */}
                    <a id="goToTop" href="#top">
                        <div id="divToTop">
                            <div id="media-sticky">
                            <i className="fas fa fa-angle-double-up fa-fw"></i>
                            </div>
                        </div>
                    </a>
                    {/* CONTENT */}
                    <Outlet/>
                    {/* PRE-SAVE NOW */}
                    <Subscription/>
                    {/* Phrase end actions */}
                    <PhraseActions />
                </div>
                {/* <!-- FOOTER --> */}
                <Footer/>
            </div>
        </div>
    </div>
    </>
    );
};