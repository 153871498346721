import {React} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import AlbumBanner from '../../features/albumBanner/AlbumBanner';
import SongsBanner from '../../features/songsBanner/SongBanner';

export default function MusicPage({lyrics, setLyrics}){
    const location = useLocation();
    document.title = 'MUSIC | ACMM OFFICIAL';
    return(
        <div>
            <Outlet/>
            {location.pathname === '/music' &&(
                <AlbumBanner main={true} margin={location.pathname === '/music'? 0: 3} lyrics={lyrics} setLyrics={setLyrics}/>
            )}
            {location.pathname !== '/music/release/songs' &&(
                <SongsBanner lyrics={lyrics} setLyrics={setLyrics}/>
            )}
        </div>
    );
}