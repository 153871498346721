import {React} from 'react';
import { NavLink, Link } from 'react-router-dom';
import './header.css';
import logo from '../../assets/logos+coverPages/logos/alter_logo2.png';

export default function Header({toggleNavPhone, toggleSocialMedia}) {
    return (
<header>
    <div id="actions">
        <i id="sidebar-trigger" className="fas fa fa-bars fa-fw" onClick={toggleNavPhone}></i>
    </div>
    <div className="site-title">
        <Link to="/home" id="ArtistLogo">
            <img src={logo} alt="artist logo"/>
        </Link>
        <Link href="/home"><h1 id="artistTitle">ACMM</h1></Link>
    </div>

    <div id="actions">
        <i id="media-trigger" className="fas fa fa-envelope fa-fw" onClick={toggleSocialMedia}></i>
    </div>

    <div id="navigation">
        <nav role="presentation">
            <ul className="nav flex-column flex-grow-1 w-100 ps-0">
                <NavLink to="/home" className="nav-link">
                    <li className="nav-item">HOME</li>
                </NavLink>
                <NavLink to="/music" className="nav-link">
                    <li className="nav-item">MUSIC</li>
                </NavLink>
                <NavLink to="/visuals" className="nav-link">
                    <li className="nav-item">VISUALS</li>
                </NavLink>
                <NavLink to="/tales" className="nav-link">
                    <li className="nav-item">TALES</li>
                </NavLink>
                {/* <NavLink to="/connect" className="nav-link">
                    <li className="nav-item">CONNECT</li>
                </NavLink> */}
            </ul>
        </nav>
    </div>

    <div className="site-title action">
        <a className="button" href="https://linktr.ee/acmmofficial" target='_blank' rel='noreferrer'>
            <span className="button_lg">
                <span className="button_sl"></span>
                <span className="button_text">Hear Music Now</span>
            </span>
        </a>
    </div>
</header>
    );
};