import React from 'react';
import AlbumsBanner from '../../../features/albumBanner/AlbumBanner';
import {albums} from '../../../data/albumData';
export default function SongsPage(){
    return(
        <>
        <div className='testMusicPage'>
            <p>ALBUMS</p>
        </div>
            {albums.map(item=><AlbumsBanner item={item.id - 1 } hideT={false}/>)}
        </>
    );
}