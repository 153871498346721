import {React} from 'react';
import { NavLink } from 'react-router-dom';

export default function Header({isNavPhoneVisible}) {
    return (
    <div id="nav-phone" style={{ display: isNavPhoneVisible ? 'block' : 'none' }}>
    <div id="phone-navigation">
        <nav role="presentation">
            <ul id="ul-phone-nav">
                <NavLink to="/home" className="nav-link">
                    <li className="nav-item">HOME</li>
                </NavLink>
                <NavLink to="/music" className="nav-link">
                    <li className="nav-item">MUSIC</li>
                </NavLink>
                <NavLink to="/visuals" className="nav-link">
                    <li className="nav-item">VISUALS</li>
                </NavLink>
                <NavLink to="/tales" className="nav-link">
                    <li className="nav-item">TALES</li>
                </NavLink>
                {/* <NavLink to="/connect" className="nav-link">
                    <li className="nav-item">CONNECT</li>
                </NavLink> */}
            </ul>
        </nav>
    </div>
</div>
    );
};