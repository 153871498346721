export const summaries = {
    IJASL:{
        story:`
        The city lay enveloped in a perpetual drizzle, a syncopated dance of raindrops and neon lights. 
        Amid urban decay stood an abandoned theater, a silent witness to forgotten dreams.
        ||
        On a gloomy day like any other, a loner wrapped in a worn trench coat was drawn to the ruined theater. The air vibrated with 
        an instrumental melody, telling stories of mystery and melancholy that echoed in the desolate urban landscape.
        ||
        Upon entering the theater entrance, a holographic screen materialized on the marquee, projecting scenes of urban desolation. 
        In the background, a call, a desperate voice trying to communicate without success. The one hidden in the city's shadows 
        that everyone knows but denies, remained elusive, fulling the air with intrigue and desolation.
        ||
        Inside, footsteps reverberated through the decaying grandeur. Holographic projections on the stage revealed just another 
        story of love, futuristic but abandoned. Couples dressed in high-tech attire, dancing in a portrait of the complexities of a 
        single passion in a familiar but bewildered world, dominated by advanced but deserted technology.
        ||
        Deep within its structure, a room was revealed where holograms reproduced scenes of joy and heartbreak. A holographic moon 
        projected an ethereal glow along with stories of youth. The stories painted a picture of the beauty and 
        absurdity of love, and each one was a simple but perfect reflection of the cliché of the dance of the passions. Suddenly, the 
        room cracked, as if a portal to another, where an old and broken hologram emerged from the depths of darkness that embodied 
        the essence of lost love. The disturbing narrative of a tragic love story resonated with great force, with shadows of the past 
        clinging to something that does not exist, or at least that no longer is.
        |||
        “Oh! Great theater, you who know about stories and thespians, reveal the desires that dwell within all.”
        |||
        Without much mystery, a wall crumbled, revealing a corridor with a warm light peeking out at the end. Once inside, 
        a new holographic display was projected, but it quickly transformed into a dance of deception. The holograms spun, 
        shattering with each beat, giving place to a dramatic chase. With fear, at all speed legs were marking its march, without 
        looking back the light that increased its splendor each time was the only objective. Close to arriving, a hand stopped 
        its step, it was a familiar but inert touch, a past, a dream. The forces of fear, and the desire to scream were inevitable. 
        New hands came out, one covering his mouth, and the rest dragging everything down. The atmosphere resonated with a struggle 
        to continue, not give in. Amid the remnants of forgotten emotions, the unresolved echoes of his own heart slowly enveloped the 
        theater. With the last breath, one last battle must have to be faced, if freedom was the desire, against the one that had always 
        been denied.
        ||
        The rain and the neon lights of the city flickered once more. The curtain fell on the stage, giving way to a new ending, which like so many, once again the only spectator was time itself.
        ||
        The city lay enveloped in a perpetual drizzle, a syncopated dance of raindrops and neon lights. Amid urban decay stood an 
        abandoned theater, a silent witness to so many endings.
        ||
        On a gloomy day like any other, the air vibrated with an instrumental melody, telling stories of mystery and melancholy that echoed in the desolate urban landscape.
        ||
        ~ The End ~
        `,
        author: "Arturo Cesar Morales Montaño"
    },
}