import React ,{useEffect} from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import {songs} from '../../../data/musicData';
import {albums} from '../../../data/albumData';

import './albumPage.css';

export default function AlbumPage({setLyrics}){
    const location = useLocation();
    const albumName = location.pathname.split('/')
    const { path } = useParams();
    const findAlbum = (array, path) =>{
        for (let i = 0; i < array.length; i++) {
            const obj = array[i];
            if (obj.path === path) {
              return i;
            }
          }
          return -1;
    }

    useEffect(()=>{
        setLyrics(false);
    }, []);


    const indexalbum = findAlbum(albums, path);
    const album = albums[indexalbum];
    const albumFilter = songs.filter((item)=> item.type === 'album-song');
    const albumSongs = albumFilter.filter((item)=> item.albumPath === albumName[albumName.length -1]);

    return (
        <>
        <div id='musicPageTemplate'>
            <div className="contentmusicPageTemplate">
                <div className="backcontentmusicPageTemplate">
                    <div className="Dircontent">
                        <div id='albumDistribution'>
                            {/* LEFT SIDE */}
                            <div id='albumInfo'>
                                <div id='coverPagealbum'>
                                    <img src={require(`../../../assets/music-cover/${album.imageUrl}`)} alt={album.title} />
                                </div>
                                <div id='albumTextInfo'>
                                    <h1>{album.title}</h1>
                                    <h2>{album.info.secondName}</h2>
                                    <p>Release: {album.date}</p>
                                    <p>Type: {album.type}</p>
                                    <p>Genre: {album.info.genre}</p>
                                    <p>Number of Songs: {album.info.numsongs}</p>
                                    <p>Duration: {album.info.duration}</p>
                                    <p>Writen by: {album.info.author}</p>

                                    <div id="musicPageMedia">
                                    <a className="youtube" href="https://www.youtube.com/channel/UCkiRMMoVkVIG8f7rNjkWciw" target='_blank' rel='noreferrer'>
                                        <i className="fa fa-youtube"></i>
                                    </a>
                                    <a className="amazon" href="https://music.amazon.com/artists/B08FHGHVJP/a-c-m-m" target='_blank' rel='noreferrer'>
                                        <i className="fa fa-amazon"></i>
                                    </a>
                                    <a className="spotify" href="https://open.spotify.com/intl-es/artist/6Y8UzdPnFY3j8Y86p0AJTI?si=E2Ltnfl3RsGNIJBb9e1qxQ" target='_blank' rel='noreferrer'>
                                        <i className="fa fa-spotify"></i>
                                    </a>
                                    <a className="Apple Music" href="https://music.apple.com/mx/artist/a-c-m-m/1527006329" target='_blank' rel='noreferrer'>
                                        <i className="fa fa-music"></i>
                                    </a>
                                    </div>
                                </div>
                            </div>
                            {/* RIGHT SIDE */}
                            <div id='albumDisplay'>
                                <div id='ytVideoMusicPage'>
                                    <div>
                                    {albumSongs.map((item)=>
                                        <p> {item.n} | <Link to={`/music/release/${item.type}/${item.path}`}>{item.title}</Link></p>
                                    )}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* INFO */}
        <div id='albumIndoSummary'>
            <h1>~ {album.actNumber} ~<br/> {album.title}</h1>
            <h2>{album.info.secondName}</h2>
            <div className='noBorder' dangerouslySetInnerHTML={{ __html: album.info.story}} />
            <p>©A. C. M. M <br/> <br/> ©{album.title} {album.year}</p>
            <Link to='/visuals'>
            <button className='loadButContent'>
                <div class="text">
                    <span>WATCH</span>
                    <span>IT</span>
                </div>
                <div class="clone">
                    <span>WATCH</span>
                    <span>IT</span>
                </div>
            </button>
            </Link>
        </div>
        </>
    );
}