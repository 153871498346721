import React from 'react';
import { Link} from 'react-router-dom';

/* COMPONENT */
export default function  MusicSquareAlbum ({ id, title, imageUrl, type, path}) {
    return (
    <Link to={`/music/release/${type}/${path}`}>
        <div className="music-square" key={id}>
        <img src={require(`../../assets/music-cover/${imageUrl}`)} alt={title} />
            <div className="overlay">
                <h1>{title}</h1>
                <p className='info'>INFO</p>
            </div>
        </div>
    </Link>
);
}