import {React} from 'react';
import './socialMedia.css';

export default function socialMedia({isSocialMediaVisible}){
    return (
    <div id="socialMedia" style={{ display: isSocialMediaVisible ? 'block' : 'none' }}>
        <div className="basr-social-share social">
            <ul id="backSocial">
                <li>
                    <a className="linkTree" href="https://linktr.ee/acmmofficial" target='_blank' rel='noreferrer'>
                        <i className="fas fa fa-asterisk"></i>
                        <span>LinkTree</span>
                    </a>
                </li>
                <li>
                    <a className="instagram" href="https://www.instagram.com/A.C.M.M_/" target='_blank' rel='noreferrer'>
                        <i className="fa fa-instagram"></i>
                        <span>Instagram</span>
                    </a>
                </li>
                <li>
                    <a className="youtube" href="https://www.youtube.com/channel/UCkiRMMoVkVIG8f7rNjkWciw" target='_blank' rel='noreferrer'>
                      <i className="fa fa-youtube"></i>
                        <span>Youtube</span>
                    </a>
                </li>        
                <li>
                    <a className="twitter" href="https://twitter.com/@ACMM_Official" target='_blank' rel='noreferrer'>
                        <i className="fa fa-twitter"></i>
                        <span>Twitter</span>
                    </a>
                </li>
                <li>
                    <a className="facebook" href="https://www.facebook.com/people/ACMM_Official/100063769561577/" target='_blank' rel='noreferrer'>
                        <i className="fa fa-facebook"></i>
                        <span>Facebook</span>
                    </a>
                </li>
                <li>
                    <a className="tiktok" href="https://www.tiktok.com/@acmm_official_" target='_blank' rel='noreferrer'>
                        <i className="fa fa-tiktok"></i>
                        <span>tiktok</span>
                    </a>
                </li>
                <li>
                    <a className="spotify" href="https://open.spotify.com/intl-es/artist/6Y8UzdPnFY3j8Y86p0AJTI?si=E2Ltnfl3RsGNIJBb9e1qxQ" target='_blank' rel='noreferrer'>
                        <i className="fa fa-spotify"></i>
                        <span>spotify</span>
                    </a>
                </li>
                <li>
                    <a className="Apple Music" href="https://music.apple.com/mx/artist/a-c-m-m/1527006329" target='_blank' rel='noreferrer'>
                        <i className="fa fa-music"></i>
                        <span>Apple Music</span>
                    </a>
                </li>
                <li>
                    <a className="Amazon Music" href="https://music.amazon.com/artists/B08FHGHVJP/a-c-m-m" target='_blank' rel='noreferrer'>
                        <i className="fa fa-amazon"></i>
                        <span>Amazon Music</span>
                    </a>
                </li>          
            </ul>
        </div>
    </div>
    );
};