import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import {albums} from '../../data/albumData';
import MusicSquareAlbum from '../../components/MusicSquareAlbum/MusicSquareAlbum'
import '../styles/MusicGrid.css'; 

/* FEATURE */
const AlbumBanner = ({item = albums.length -1, margin = 0, hideT = true, hideB = true, main = false}) => {
    console.log('hello', albums[item].id)
  return (
    <div > 
        <div className='centerFix'>
        <div className="music-grid" style={{margin:'0', padding:'2% 0 0 0'}}>

            {/* Albums */}
                {hideT && (
                    <div className='testMusicPage'>
                    <p>ALBUM</p>
                    </div>
                )}

                <div id='albumMusic'>

                <MusicSquareAlbum
                key={albums[item].id}
                id={albums[item].id}
                title={albums[item].title}
                imageUrl={albums[item].imageUrl}
                type={albums[item].type}
                path={albums[item].path}
                />

            </div>
            <div className="spotify-player">
                <iframe src={`https://open.spotify.com/embed/album/${albums[item].embed}`}  frameborder="0" allowtransparency="true" allow="encrypted-media" title="Spotify"></iframe>
            </div>


            {(hideB && !main) && (
            <Link to={`/music/release/album/${albums[item].path}`}style={{width: '100%', 'textAlign': 'center', margin:'0px', padding:`0 0 ${margin}% 0`}}>
                <button className='loadButContent'>
                <div class="text">
                    <span>HEAR</span>
                    <span>ALBUM</span>
                </div>
                <div class="clone">
                    <span>HEAR</span>
                    <span>ALBUM</span>
                </div>
            </button>
            </Link>
            )}

            {(hideB && main) && (
            <Link to={`/music/release/albums`}style={{width: '100%', 'textAlign': 'center', margin:'0px', padding:`0 0 ${margin}% 0`}}>
                <button className='loadButContent'>
                <div class="text">
                    <span>ALL</span>
                    <span>ALBUMS</span>
                </div>
                <div class="clone">
                    <span>ALL</span>
                    <span>ALBUMS</span>
                </div>
            </button>
            </Link>
            )}
        </div>
        </div>
    </div>

  );
};

export default AlbumBanner;
