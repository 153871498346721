import React, { useState, useMemo } from 'react';
import {Link, useLocation} from 'react-router-dom';
import {songs} from '../../data/musicData';
import {shuffleArray} from '../functions';
import MusicSquareSong from '../../components/MusicSquareSong/MusicSquareSong';
import '../styles/MusicGrid.css'; 

/* FEATURE */
const SongsBanner = ({lyrics, setLyrics, allSongs = false}) => {
    const location = useLocation();
    const currentPath = location.pathname;
    const targetPath = '/music/release/songs';
    const [visibleSongs, setVisibleSongs] = useState(allSongs? songs.length : 4);
    const [ranSongs, setRanSongs] = useState([]);

    useMemo(()=>{
        setRanSongs(shuffleArray(songs));
    },[currentPath])

  return (
    <div> 
        <div className='centerFix'>
        <div className="music-grid">

        <div className='testMusicPage'>
            <p>MUSIC</p>
        </div>

        {/* SONGS */}
            {[...ranSongs.slice(0, visibleSongs)].map((item) => (
                <MusicSquareSong
                key={item.id}
                id={item.id}
                title={item.title}
                imageUrl={item.imageUrl}
                type={item.type}
                path={item.path}
                setLyrics={setLyrics}
                />
            ))}


        {(currentPath !== targetPath) && (
                    <>
                <Link to='/music/release/songs'>
                    <button className='loadButContent'>
                    <div class="text">
                        <span>MORE</span>
                        <span>MUSIC</span>
                    </div>
                    <div class="clone">
                        <span>MORE</span>
                        <span>MUSIC</span>
                    </div>
                    </button>
                </Link>
                </>
            )}

            {(currentPath === targetPath) && (
            <Link to={`/music`}style={{width: '100%', 'textAlign': 'center', margin:' 0 0 -4% 0', padding:`0`}}>
                <button className='loadButContent'>
                <div class="text">
                    <span>GO</span>
                    <span>BACK</span>
                </div>
                <div class="clone">
                    <span>GO</span>
                    <span>BACK</span>
                </div>
            </button>
            </Link>
            )}

        </div>
        </div>
    </div>
  );
};

export default SongsBanner;
