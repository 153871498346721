import React from 'react';

const NotFound = () => {
  document.title = 'NOT FOUND | ACMM OFFICIAL';
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' , height:'700px', display:'flex' , flexDirection: 'column', alignItems:'center', justifyContent:'center'}}>
      <h2>404 Not Found</h2>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;