import React, {useEffect, useState} from 'react';
import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route, Navigate, useLocation} from 'react-router-dom';
import AppLayout from './AppLayout';
import HomePage from '../pages/home/HomePage';
import MusicPage from '../pages/music/MusicPage';
import SongPage from '../pages/music/releasePath/SongPage';
import AlbumPage from '../pages/music/releasePath/AlbumPage';
import TalesPage from '../pages/tales/Tails';
import SongsPage from '../pages/music/songsPage/SongsPage';
import AlbumsPage from '../pages/music/albumsPage/AlbumsPage';
import VisualsPage from '../pages/visuals/visualsPage';
import NotFound from '../pages/notFound/NotFound';

/* SCROLL */
function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
}


function App() {
  /* GLOBAL STATES */
  const [lyrics, setLyrics] = useState(false);
   /* LOADER */
   useEffect(() => {
    const path = window.location.pathname;

    // Conditionally import CSS file based on the current URL path
    if (path !== '/home' && path !== '/about' && path !== '/music' && path !== '/visuals') {
      import('./loaderHide.css').then(() => {
        console.log('CSS file for path1 imported');
      }).catch(error => {
        console.error('Error importing CSS file:', error);
      });
    } 
  }, []);
  
  /* ROUTER */
  const router = createBrowserRouter(createRoutesFromElements(
    <>
    <Route path='/' element={
      <>
      <ScrollToTop />
      <AppLayout/>
      </>
    }>
      <Route index element={ <Navigate to="/home"/> }/>
      <Route path="home" element={<HomePage/>}/>
      {/* MUSIC PATH */}
      <Route path="music" element={<MusicPage lyrics={lyrics} setLyrics={setLyrics}/>}>
        <Route path="release/album/:path" element={<AlbumPage lyrics={lyrics} setLyrics={setLyrics}/>}/>
        <Route path="release/:type/:path" element={<SongPage lyrics={lyrics} setLyrics={setLyrics}/>}/>
        <Route path="release/songs" element={<SongsPage lyrics={lyrics} setLyrics={setLyrics}/>}/>
        <Route path="release/albums" element={<AlbumsPage/>}/>
      </Route>
      {/* VISUALS PATH */}
      <Route path='visuals' element={<VisualsPage/>}/>

      {/* TALES ROUTE */}
      <Route path='/tales' element={<TalesPage/>}/>
      
      {/* FAIL */}
      <Route path='*' element={<NotFound/>} />
    </Route>
     </>
  )
  );
  
  return (
    <RouterProvider router={router} basename='/*'/>
  );
}

export default App;