import {React} from 'react';
import './subscription.css';

export default function Subscription(){
    const handleSubmit = (e) => {
        e.preventDefault();
        alert('Sorry! Service Not Available For The Moment. Try Again Later');
      };
    
    return (
    <section className="subscribe">
        <div className="preSave">
            <p> ACMM PROJECT | MUSIC</p>
            <div className="fix-btn">
                <div>
                    <a href="https://open.spotify.com/intl-es/artist/6Y8UzdPnFY3j8Y86p0AJTI?si=E2Ltnfl3RsGNIJBb9e1qxQ" className="btn" target='_blank' rel='noreferrer'><i className="animation"></i> <i className="fa fa-spotify" style={{"fontSize": "1.2rem"}}></i>SPOTIFY<i className="animation"></i>
                    </a>
                </div>   
                <div>
                    <a href="https://music.apple.com/mx/artist/a-c-m-m/1527006329" className="btn2" target='_blank' rel='noreferrer'><i className="animation"></i> <i className="fa fa-music" style={{"fontSize": "1.2rem"}}></i>APPLE MUSIC<i className="animation"></i>
                    </a>
                </div>
                <div>
                    <a href="https://music.amazon.com/artists/B08FHGHVJP/a-c-m-m" className="btn3" target='_blank' rel='noreferrer'><i className="animation"></i> <i className="fa fa-amazon" style={{"fontSize": "1.2rem"}}></i>AMAZON MUSIC<i className="animation"></i>
                    </a>
                </div>
                </div>
        </div>
        <div className="preSave">
            <p> ACMM PROJECT | THE SERIE</p>
            <div className="fix-btn"> 
                <div style={{'width':'80%'}}>
                    <a href="https://www.youtube.com/channel/UCkiRMMoVkVIG8f7rNjkWciw" className="btn4" target='_blank' rel='noreferrer'><i className="animation"></i> <i className="fa fa-youtube" style={{"fontSize": "1.5rem"}}></i>Youtube<i className="animation"></i>
                    </a>
                </div>
            </div>
        </div>
            <div className="mailing">
                <p style= {{"padding": "2%;"}}>Mailing | Stay Connected</p>
                <div className="input-container">
                    <form id="formmail" onSubmit={handleSubmit}>
                        <input 
                        required="" 
                        placeholder="Email Address" 
                        type="email" 
                        />
                        <button className="invite-btn" type="submit">Subscribe</button> 
                    </form>
            </div>
        </div>
    </section>
    );
}