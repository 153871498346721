import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import AlbumBanner from '../../features/albumBanner/AlbumBanner';
import './serie.css';
import {albums} from '../../data/albumData';

export default function TheSerie(){
    const [hide, setHide] = useState(true);
    const [n, setN] = useState(0);
    function tale(n){
        setHide(!hide);
        setN(n)
    }
    return(
        <section>
            <div id='chapters'>
                <div id='visualsPage'>
                    <h1>TALES</h1>
                </div>
                <button onClick={()=>tale(0)}><h1>It's Just Another Story Of Love</h1></button>

                {/* INFO */}

                {hide? <></> : (
                <div id='albumIndoSummary'>
                <h1>~ {albums[n].actNumber} ~<br/> {albums[n].title}</h1>
                <h2>{albums[n].info.secondName}</h2>
                <div className='noBorder' dangerouslySetInnerHTML={{ __html: albums[n].info.story}} />
                <p>A. C. M. M </p>
                        <Link to='/music/release/albums'>
                            <button className='loadButContent'>
                                    <div class="text">
                                    <span>HEAR</span>
                                    <span>IT</span>
                                </div>
                                <div class="clone">
                                    <span>HEAR</span>
                                    <span>IT</span>
                                </div>
                            </button>
                        </Link>
                </div>
            )}
            </div>

            <div>
                <AlbumBanner margin={3}/>
            </div>
        </section>
        
    )
}