import {lyrics}  from './lyrics';
/* Format lyrics */
export function formatString(inputString) {
  var stringArray = inputString.split('|');
  var stringWithBreaks = stringArray.join('<br/>');
  var finalString = '<p>' + stringWithBreaks + '</p>';
  return finalString;
}

/* Songs */
export const songs = [
    {
    id: 20,
    title: 'La Chica Del Vestido Azul (Labios de Caramelo)',
    date:'2024-11-22',
    path: 'vestido-azul',
    type: 'single',
    imageUrl: 'vestidoazul.png',
    info: {genre: 'Alternative',
         author: 'Arturo Cesar Morales Montano',
         songType: 'Lyric',
        }, 
    lyrics: formatString(lyrics.vaz),
    videoId: '2fThupjTEX0',
    },
    {
      id: 19,
      title: 'Lo Que Fue',
      date:'2024-08-2',
      path: 'lo-que-fue',
      type: 'single',
      imageUrl: 'loquefue.png',
      info: {genre: 'Alternative',
           author: 'Arturo Cesar Morales Montano',
           songType: 'Lyric',
          }, 
      lyrics: formatString(lyrics.lqf),
      videoId: '1RU8M2VmdCg',
    },
    {
      id: 1,
      title: 'C.L | Calling Out For Love',
      date:'2020-07-21',
      path: 'calling-out-for-love',
      type: 'single',
      imageUrl: 'C.L.jpg',
      info: {genre: 'Alternative',
             author: 'Arturo Cesar Morales Montano',
             songType: 'Lyric',
            }, 
      lyrics: formatString(lyrics.cl),
      videoId: 'w4UxpKp0ppU',
    },
    {
        id: 2,
        title: "Lost Shadows",
        date:'2022-03-23',
        path: 'lost-shadows',
        type: 'single',
        imageUrl: 'Lost shadows.png',
        info: {genre: 'Alternative',
             author: 'Arturo Cesar Morales Montano',
             songType: 'Lyric',
            }, 
        lyrics:formatString(lyrics.ls),
        videoId: 'o2fJTZA2Nxg',
    },
    {
        id: 3,
        title: "Beyond Moon's Love",
        path: 'Beyond_Moons_Love.png',
        date:'2022-06-01',
        type: 'single',
        imageUrl: 'bmlCp.png',
        info: {genre: 'Alternative',
             author: 'Arturo Cesar Morales Montano',
             songType: 'Lyric',
            }, 
        lyrics:formatString(lyrics.byms),
        videoId: 'E0KAwazTZZA',
      },

    /* 
    
    PARALLEL 
    
    */
    {
      id: 15,
      n: 1,
      title: "Beyond Moon's Love - Parallel ",
      date:'2024-06-20',
      path: 'beyond-moon-s-love-parallel',
      albumPath:'parallel',
      type: 'album-song',
      album: "Parallel",
      imageUrl: 'Parallel.png',
      info: {genre: 'Alternative',
           author: 'Arturo Cesar Morales Montano',
           songType: 'Lyric',
          }, 
      lyrics:formatString(lyrics.byms),
      videoId: 'JFTYCi1LrlE',
  },
  {
    id: 16,
    n: 2,
    title: "Calling Out For Love (C.L) - Parallel",
    date:'2024-06-20',
      path: 'calling-out-for-love-cl-parallel',
      albumPath:'parallel',
      type: 'album-song',
      album: "Parallel",
      imageUrl: 'Parallel.png',
      info: {genre: 'Alternative',
           author: 'Arturo Cesar Morales Montano',
           songType: 'Lyric',
          }, 
      lyrics:formatString(lyrics.cl),
      videoId: 'IxIslwBGRno',
},
{
  id: 17,
  n: 3,
  title: "Whispering Lies - Parallel",
  date:'2024-06-20',
      path: 'whispering-lies-parallel',
      albumPath:'parallel',
      type: 'album-song',
      album: "Parallel",
      imageUrl: 'Parallel.png',
      info: {genre: 'Alternative',
           author: 'Arturo Cesar Morales Montano',
           songType: 'Lyric',
          }, 
      lyrics:formatString(lyrics.wl),
      videoId: 'BnRftABUsJo',
},
  ];