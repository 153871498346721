import React, { useState, useEffect } from 'react';
import { API_URL } from '../indexPath';

const YouTubeVideo = ({ vidId }) => {
  const [videoId, setVideoId] = useState('');

  useEffect(() => {
    if (vidId === undefined || vidId === '') {
      const fetchLatestVideo = async () => {
        try {
          const response = await fetch(`${API_URL}/api/latestVideo`);
          const data = await response.json();
          const latestVideoId = data.videoId;
          setVideoId(latestVideoId);
        } catch (error) {
          console.error('Error fetching YouTube data:', error);
        }
      };
      fetchLatestVideo();
    } else {
      setVideoId(vidId);
    }
  }, [vidId]); 

  return (
    <div style={{ height: '100%' }}>
      <iframe
        width="100%"
        height="100%"
        src={`https://www.youtube.com/embed/${videoId}`}
        title="Latest Video"
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
};

export default YouTubeVideo;
